<template>
  <div class="input_arr">
    <el-input
        class="arr_input"
        placeholder="请输入内容"
        v-model="text"
       @keyup.enter.native="key_up"
       v-focus
        clearable>
    </el-input>
    <div class="ipt_btn" @click="seach">{{btn_text}}</div>
    <!-- <el-button type="info" plain class="ipt_btn">信息按钮</el-button> -->
  </div>
</template>

<script>
export default {
  name: "seachInput",
  props: {
    // text: {
    //   type: String
    // },
    btn_text:{
      type: String,
      default:'搜 索'
    }
  },
  data() {
    return {
      text:''
    };
  },
  directives: {
    focus: {
      inserted(el) {
        el.children[0].focus();
      },
    },
  },
  computed: {},
  methods: {
    seach() {
      this.$emit("seach",this.text);
    },
    key_up(e){
      if(e.keyCode === 13){
        this.seach();
      }
    },
  },
  watch:{
    text(){
      this.seach()
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../utils/tool.scss";
.input_arr {
  @include flex-row;
  height: 40px;
  line-height: 40px;
}
/deep/.el-input{
   width: 200px !important;
}
/deep/.el-input__inner {
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 4px 0 0 4px !important;
}
.ipt_btn{
  border: 1px solid#DCDFE6;
  height: 40px !important;
  line-height: 40px !important;
  padding:0 5px ;
  color:#b7b7b7;
  background: #f5f5f5;
  border-left:1px solid transparent;
  border-radius: 0 4px 4px 0 !important;
  cursor: pointer;
  &:hover{
    color: #409eff;
    background: #eeeeee
  }
}
</style>