<template>
  <div class="mine_shelf" center_bgc>
    <seachInput
      :btn_text="'搜书架'"
      @seach="search_shelf"
      :text="value"
    ></seachInput>
    <el-table
      :data="tableData"
      ref="my_shelf"
      style="width: 100%"
      @select="handle_select"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="cover_img" label="封面" width="150" align="center">
        <template slot-scope="scope">
          <div class="cover_img_box">
            <div class="rank_one">
              <img
                v-if="scope.row.cover_image"
                :src="scope.row.cover_image"
                alt=""
                class="cover_img"
                @click="to_bookinfo(scope.row.id)"
              />
              <img
                v-else
                src="../../assets/images/default.png"
                alt=""
                class="cover_img"
                @click="to_bookinfo(scope.row.id)"
              />
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="章节" align="center">
        <template slot-scope="scope">
          <div class="out_box">
            <div class="shelf_item">
              <span
                class="shelf_item_top shelf_item_name"
                @click="to_bookinfo(scope.row.id)"
                >{{ scope.row.name }}</span
              >
              <span class="shelf_item_btm"
                ><span
                  class="shelf_item_author"
                  @click="
                    scope.row.author_id
                      ? to_authorinfo(scope.row.author_id)
                      : ''
                  "
                  :class="scope.row.author_id ? 'intro_author' : 'un_author'"
                  >{{ scope.row.author }}</span
                >
                著
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="new_push" label="更新" align="center">
        <template slot-scope="scope">
          <div class="out_box">
            <div class="shelf_item">
              <span class="shelf_item_top">{{ scope.row.update_time }}</span>
              <span
                class="shelf_item_btm shelf_item_chapter"
                @click="to_read(scope.row.id, scope.row.update_page)"
                >{{ scope.row.update_title }}</span
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="read" label="读至" align="center">
        <template slot-scope="scope">
          <div class="out_box">
            <div class="shelf_item">
              <span
                class="shelf_item_top"
                v-text="scope.row.title ? '读至' : '尚未阅读'"
              ></span>
              <span
                class="shelf_item_btm shelf_item_chapter"
                @click="to_read(scope.row.id, scope.row.page)"
                v-if="scope.row.section_status && scope.row.title"
                >{{ scope.row.title }}</span
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="do_it" label="操作" align="center">
        <template slot-scope="scope">
          <div class="out_box">
            <div class="shelf_item">
              <span class="shelf_item_delete" @click="delete_row(scope.row.id)"
                >删除</span
              >
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="shelf_footer">
      <div flex between align>
        <div class="choose_arr">
          <el-checkbox v-model="checked" @change="change_select(tableData)"
            >全选</el-checkbox
          >
          <div class="del_all" @click="delete_selection">删除</div>
        </div>
        <c-page :callBack="loadData" :page="page"></c-page>
      </div>
    </div>
  </div>
</template>
<script>
import { Mybook, Booksdelete } from "@/https/mine_center.js";
import seachInput from "@/components/input/seach-input";
export default {
  name: "mine_shelf",
  components: {
    seachInput,
  },
  data() {
    return {
      checked: false,
      page: { limit: 10, page: 1, total: 0 },
      value: "",
      tableData: [
        // {cover_img:require("@/assets/images/def_books.png"),name:'',},
        // {cover_img:require("@/assets/images/def_books.png"),name:'',},
        // {cover_img:require("@/assets/images/def_books.png"),name:'',},
        // {cover_img:require("@/assets/images/def_books.png"),name:'',},
      ],
      checked_arr: [],
    };
  },
  methods: {
    loadData() {
      this.get_shelf(this.value, this.page.page);
      this.checked_arr = [];
    },
    // 全选
    change_select(row) {
      console.log(row);
      if (this.checked) {
        this.$refs.my_shelf.clearSelection();
        row.forEach((item) => {
          this.$refs.my_shelf.toggleRowSelection(item);
        });
        this.checked_arr = row;
      } else {
        this.$refs.my_shelf.clearSelection();
        this.checked_arr = [];
      }
    },
    handle_select(selection, row) {
      // selection已经勾选的所有行
      // row当前行
      console.log(selection, row);
      this.checked_arr = selection;
    },
    // 点击当前行的删除按钮
    delete_row(id) {
      // 直接删除
      let params = {
        string: String(id),
      };
      this.delete_data(params);
      // 先判断当前行是否已经被选中
      // this.checked_arr.forEach((el) => {
      //   if (el.id === id) {

      //   }
      // });
    },
    // 点击底部删除按钮
    delete_selection() {
      if (this.checked_arr.length > 0) {
        let delete_str = "";
        this.checked_arr.forEach((el) => {
          delete_str = delete_str + el.id + ",";
        });
        delete_str = delete_str.substring(0, delete_str.length - 1);
        console.log(delete_str);
        let params = {
          string: delete_str,
        };
        this.delete_data(params);
        this.checked = false;
      } else {
        this.$message({
          message: "请选择要移除的书籍",
          type: "warning",
        });
      }
    },
    //调用删除接口
    delete_data(params) {
      Booksdelete(params).then((res) => {
        console.log(res.data);
        if (res.code === 0) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.checked = false;
          this.get_shelf("", this.page.page);
          this.get_shelf("", this.page.page);
        }
      });
    },
    // 获取个人中心书架信息
    get_shelf(search_name, page) {
      let params = {
        search_name: search_name || "",
        ...this.page,
      };
      Mybook(params).then((res) => {
        console.log("个人中心书架", res.data);
        this.tableData = res.data.data;
        this.page.total = res.data.total;
      });
    },
    // 搜索
    search_shelf(val) {
      this.get_shelf(val, 1);
    },
    // 点击去到阅读界面
    to_read(book_id, page_id) {
      sessionStorage.setItem("book_id", book_id);
      const { href } = this.$router.resolve({
        path: "/read_book",
        query: {
          id: book_id,
          page: page_id,
        },
      });
      window.open(href, "_blank");
    },
    // 点击书籍名称或图片查看书籍详情
    to_bookinfo(id) {
      const { href } = this.$router.resolve({
        path: "/show_book",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    // 点击作者查看作者详情
    to_authorinfo(id) {
      this.$router.push({
        path: "/show_author",
        query: {
          id: id,
        },
      });
    },
  },
  created() {
    this.get_shelf();
  },
  watch: {
    // 监听选择项
    checked_arr() {
      if (this.checked_arr.length === this.tableData.length) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    // value(val){
    //   this.search_shelf(val);
    // }
  },
};
</script>
<style lang="scss" scoped>
@import "../../utils/tool.scss";
// @include flex-center();
.mine_shelf {
  position: relative;
  padding: 20px 15px 75px 15px;
  // border: 1px solid #e4e4e4;
  // box-shadow: 0 0 5px #e4e4e4;
  border-radius: 4px;
  .input_arr {
    margin-bottom: 20px;
  }
}
.cover_img_box {
  width: 100%;
  cursor: pointer;
  @include flex-center();
  .cover_img {
    width: 88px;
    height: 124px;
  }
}
.rank_one {
  width: 88px;
  height: 124px;
  overflow: hidden;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05), 0 0 5px #f9f2e9 inset;
  img {
    cursor: pointer;
    width: 88px;
    height: 124px;
    transition: all 0.5s;
  }
  img:hover {
    transform: scale3d(1.1, 1.1, 1.1);
  }
}
.out_box {
  color: #999;
  text-align: center;
  & div {
    text-align: center;
  }
  img {
    cursor: pointer;
  }
  .shelf_item_delete:hover {
    color: #fc6868;
  }
  .shelf_item_chapter {
    color: #0C2962;
    cursor: pointer;
  }
  .shelf_item_delete,
  .shelf_item_name {
    cursor: pointer;
    color: #333;
  }
  .shelf_item_name {
    font-size: 14px;
  }
  .shelf_item_name:hover,
  .shelf_item_chapter:hover {
    color: #409eff;
  }
  .intro_author {
    font-size: 12px;
    font-weight: normal;
    cursor: pointer;
    color: #666;
  }
  .intro_author:hover {
    color: rgba(79, 156, 251, 1);
  }
  .un_author {
    font-size: 12px;
    font-weight: normal;
    color: #666;
  }
  width: 100%;
  @include flex-center();
}
.shelf_item {
  @include flex-column-center();
  line-height: 30px;
}
/deep/ .el-table {
  text-align: center;
}
/deep/.el-table__header-wrapper {
  display: none;
}
.shelf_footer {
  position: absolute;
  left: 15px;
  bottom: 20px;
  width: calc(100% - 20px);
  > div {
    width: 100%;
    padding: 10px 0;
  }
}
/deep/.cPage {
  margin-top: 0px !important;
}
.choose_arr {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  .del_all {
    margin-left: 15px;
    cursor: pointer;
  }
  .del_all:hover {
    color: #fc6868;
  }
}
</style>

